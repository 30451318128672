import { EngineEnum } from "../enums/engine";
import { SourceEnum } from "../enums/template";

export enum ArticleStatusEnum {
  GENERATING = 1,
  COMPLETED = 2,
  ERROR = 3,
}

export const ArticleStatusMap = {
  [ArticleStatusEnum.GENERATING]: "Generating",
  [ArticleStatusEnum.COMPLETED]: "Completed",
  [ArticleStatusEnum.ERROR]: "Error",
};

export const ArticleStatusColorMap = {
  [ArticleStatusEnum.GENERATING]: "#2563eb",
  [ArticleStatusEnum.COMPLETED]: "#22c55e",
  [ArticleStatusEnum.ERROR]: "#dc2626",
};

export class Article {
  article_id: string;
  template_id: string;
  template_name: string;
  media_url: string;
  s3_transcription_url: string;
  s3_image_url: string;
  s3_article_url: string;
  title: string;
  posted_url: string;
  posted_at: string;
  article_setting_name: string;
  article_word_count: number;
  created_at: string;
  generate_error: string;
  is_auto_run: boolean;
  article_status_id: ArticleStatusEnum;
  source_id: SourceEnum;
  tokens_used: number;
  engine_id?: EngineEnum;

  constructor({
    article_id,
    template_id,
    template_name,
    media_url,
    s3_transcription_url,
    s3_image_url,
    s3_article_url,
    title,
    posted_url,
    posted_at,
    article_setting_name,
    article_word_count,
    created_at,
    generate_error,
    is_auto_run,
    article_status_id,
    source_id,
    tokens_used,
    engine_id,
  }: any) {
    this.article_id = article_id;
    this.template_id = template_id;
    this.template_name = template_name;
    this.media_url = media_url;
    this.s3_transcription_url = s3_transcription_url;
    this.s3_image_url = s3_image_url;
    this.s3_article_url = s3_article_url;
    this.title = title;
    this.posted_url = posted_url;
    this.posted_at = posted_at;
    this.article_setting_name = article_setting_name;
    this.article_word_count = article_word_count;
    this.created_at = created_at;
    this.generate_error = generate_error;
    this.is_auto_run = is_auto_run;
    this.article_status_id = article_status_id;
    this.source_id = source_id;
    this.tokens_used = tokens_used;
    this.engine_id = engine_id;
  }
}
