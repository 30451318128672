import { UserIcon } from "@heroicons/react/20/solid";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Avatar from "../../components/Avatar";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import MediaUploader from "../../components/MediaUploader";
import PageHeading from "../../components/PageHeading";
import { RoleEnum, RoleMap } from "../../enums/user";
import { Account } from "../../models";
import { useUpdateAccountByIdMutation } from "../../redux/services/account";
import { useGetUserQuery } from "../../redux/services/user";
import { uploadAvatar } from "../../utils";
import TemplateContainer from "../TemplateCreate/components/common/TemplateContainer";
import EditAccount from "./EditAccount";

const headers = [
  { key: "company_name", label: "Company Name" },
  { key: "role_id", label: "Role" },
  { key: "timezone", label: "Timezone" },
];

const Accounts = () => {
  const { data: user, isLoading: isLoadingUser } = useGetUserQuery();
  const token = useSelector((state: any) => state.auth.token);
  const [
    updateAccountById,
    {
      isLoading: isLoadingUpdateAccount,
      isSuccess: isSuccessUpdateAccount,
      isError: isErrorUpdateAccount,
      error: errorUpdateAccount,
    },
  ] = useUpdateAccountByIdMutation();

  const [account, setAccount] = useState<Account>();
  const [editAccount, setEditAccount] = useState<boolean>();
  const [editAccountImage, setEditAccountImage] = useState<boolean>();

  useEffect(() => {
    if (isSuccessUpdateAccount) {
      toast.success("Updated");
      setEditAccountImage(false);
    }
  }, [isSuccessUpdateAccount]);

  useEffect(() => {
    if (isErrorUpdateAccount) {
      toast.error(
        (errorUpdateAccount as any)?.data?.detail || "Failed to update"
      );
    }
  }, [isErrorUpdateAccount, errorUpdateAccount]);

  if (isLoadingUser) return null;

  const accounts = user?.accounts || [];

  const handleAccountImageUpload = async (file: any, b: any) => {
    const uploadedFileURL = await uploadAvatar(file, token);
    if (uploadedFileURL) {
      await updateAccountById({
        account_id: account?.account_id,
        company_logo_url: uploadedFileURL,
      })
        .unwrap()
        .catch(() => {});
    }
  };

  return (
    <TemplateContainer>
      <PageHeading heading="Accounts" />
      <div className="inline-block min-w-full py-2 align-middle">
        <table className="min-w-full border-separate border-spacing-0">
          <thead>
            <tr>
              {headers.map(({ key, label }) => (
                <Header
                  key={key}
                  header={label}
                />
              ))}
              <Header header={""} />
            </tr>
          </thead>
          <tbody>
            {accounts.map((account: any, index: number) => (
              <tr key={index}>
                {headers.map(({ key }) => {
                  let data = account[key];
                  if (key === "role_id") {
                    data = (
                      <div className="flex gap-1 items-center text-gray-600">
                        <UserIcon className="h-4 w-4 " />
                        {RoleMap[data as RoleEnum]}
                      </div>
                    );
                  } else if (key === "company_name") {
                    data = (
                      <div className="flex gap-2 items-center">
                        <Avatar
                          round
                          color="lightgray"
                          src={account.company_logo_url || ""}
                          size="32px"
                        />
                        <div className="text-gray-600 max-w-[5rem] md:max-w-md lg:max-w-lg truncate">
                          {account[key]}
                        </div>
                      </div>
                    );
                  }
                  return (
                    <Data
                      key={key}
                      data={data}
                    />
                  );
                })}
                <Data
                  className="text-end"
                  data={
                    <Dropdown
                      position="right"
                      button={
                        <Button
                          variant="icon"
                          onClick={() => {}}
                        >
                          <EllipsisHorizontalIcon className="size-6 text-gray-900" />
                        </Button>
                      }
                      menuItems={[
                        { key: "edit", label: "Edit" },
                        { key: "change_photo", label: "Change Photo" },
                      ]}
                      onClick={(key: string) => {
                        setAccount(account);
                        if (key === "edit") {
                          setEditAccount(true);
                        } else if (key === "change_photo") {
                          setEditAccountImage(true);
                        }
                      }}
                    />
                  }
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {editAccount && (
        <EditAccount
          account={account as Account}
          loading={isLoadingUpdateAccount}
          errorMessage={""}
          onRequestClose={() => {
            setAccount(undefined);
            setEditAccount(false);
          }}
        />
      )}
      {editAccountImage && (
        <MediaUploader
          heading="Upload Photo"
          src={account?.company_logo_url || ""}
          loading={false}
          onClose={() => setEditAccountImage(false)}
          onUpload={handleAccountImageUpload}
          onDelete={() => {}}
        />
      )}
    </TemplateContainer>
  );
};

const Header = ({ header }: { header: string }) => (
  <th
    scope="col"
    className="uppercase text-2xs tracking-wide sticky top-0 z-10 border-b border-gray-200 bg-white bg-opacity-75 py-3.5 text-left text-gray-700 backdrop-blur backdrop-filter"
  >
    {header}
  </th>
);

const Data = ({ data, className }: { data: any; className?: string }) => {
  return (
    <td
      className={classNames(
        "divide-y divide-y-200 bg-white whitespace-nowrap py-4 text-sm font-medium text-gray-600",
        className
      )}
    >
      {data}
    </td>
  );
};

export default Accounts;
