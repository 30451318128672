import { useGetUserQuery } from "../redux/services/user";

const useUser = () => {
  const { data: user, isLoading: loading } = useGetUserQuery();
  return {
    user,
    loading,
  };
};

export default useUser;
