import React from "react";
import { Template } from "../../../models";
import SourceNAutomation from "./SourceNAutomation";
import TemplateName from "./TemplateName";

type SetupProps = {
  values: Template;
  errors: any;
  setFieldValue: any;
  setErrors: any;
};

const Setup = ({ values, errors, setFieldValue, setErrors }: SetupProps) => {
  return (
    <div className="animate-fadeIn space-y-6">
      <TemplateName
        values={values}
        error={errors["name"] as string}
        setFieldValue={setFieldValue}
      />
      <SourceNAutomation
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        setErrors={setErrors}
      />
    </div>
  );
};

export default Setup;
