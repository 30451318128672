import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Logo from "../../components/Logo";
import Message from "../../components/Message";
import TermsAndConditions from "../../components/TermsAndConditions";
import { Heading } from "../../components/Typography";
import { ROUTE_LOGIN, ROUTE_SUCCESSFUL_SIGN_UP } from "../../constants/routes";
import SpringContainer from "../../containers/SpringContainer";
import { useRegisterMutation } from "../../redux/services/auth";
import { browserTimeZone } from "../../utils";
import PasswordValidator from "./components/PasswordValidator";

const signupSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .required("Required")
    .matches(
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]).{8,}$/,
      "Password must meet the guidelines"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
});

type signUpFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const initialValues: signUpFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const SignUpEmail = () => {
  const navigate = useNavigate();
  const [register, { isLoading }] = useRegisterMutation();
  const [signUpError, setSignUpError] = useState("");

  const handleSignUp = useCallback(
    async (values: signUpFormValues) => {
      setSignUpError("");
      const { firstName, lastName, email, password } = values;
      await register({
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        timezone: browserTimeZone,
        is_super: false,
      })
        .unwrap()
        .then((res) => {
          if (res?.user_id) {
            navigate(
              ROUTE_SUCCESSFUL_SIGN_UP + `?email=${encodeURIComponent(email)}`
            );
          } else {
            setSignUpError("Something went wrong. Please contact support.");
          }
        })
        .catch((error) => {
          if (error?.status === 400 && error?.data?.detail) {
            setSignUpError(error.data.detail);
          } else {
            setSignUpError("Something went wrong. Please contact support.");
          }
        });
    },
    [navigate, register]
  );

  const navigateToLogin = useCallback(() => navigate(ROUTE_LOGIN), [navigate]);

  return (
    <SpringContainer
      centered
      className="relative"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSignUp(values)}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={signupSchema}
      >
        {(props) => {
          const { values, errors, setFieldValue } = props;
          return (
            <div className="mx-auto w-full max-w-sm space-y-6 text-center">
              <Logo />
              <Heading size="xl">Create a free account</Heading>
              <Message
                type="error"
                message={signUpError}
                className="justify-center"
              />
              <Form className="space-y-6">
                <div className="flex gap-6 flex-col md:gap-2 md:flex-row">
                  <Input
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    type="text"
                    value={values.firstName}
                    errorMessage={errors?.firstName}
                    onChange={(e) => setFieldValue("firstName", e.target.value)}
                  />
                  <Input
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    type="text"
                    value={values.lastName}
                    errorMessage={errors?.lastName}
                    onChange={(e) => setFieldValue("lastName", e.target.value)}
                  />
                </div>
                <Input
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  errorMessage={errors?.email}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                />
                <div>
                  <Input
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    value={values.password}
                    errorMessage={errors?.password}
                    onChange={(e) => {
                      const password = e.target.value;
                      setFieldValue("password", password);
                    }}
                  />
                  <PasswordValidator password={values.password} />
                </div>
                <Input
                  id="confirmPassword"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  errorMessage={errors?.confirmPassword}
                  onChange={(e) =>
                    setFieldValue("confirmPassword", e.target.value)
                  }
                />
                <Button
                  fullWidth
                  type="submit"
                  variant="primary"
                  onClick={() => {}}
                  loading={isLoading}
                >
                  Sign Up
                </Button>
              </Form>
            </div>
          );
        }}
      </Formik>
      <div className="absolute bottom-4">
        <p className="text-center text-sm text-gray-500">
          Already a member?{" "}
          <Button
            paddingZero
            variant="transparent-primary"
            onClick={navigateToLogin}
          >
            Sign In
          </Button>
        </p>
        <TermsAndConditions />
      </div>
    </SpringContainer>
  );
};

export default SignUpEmail;
