// Claude - https://docs.anthropic.com/en/docs/about-claude/models#model-names
// ChatGPT - https://platform.openai.com/docs/models#models-overview
// Gemini - https://ai.google.dev/gemini-api/docs/models/gemini#gemini-1.5-flash

export enum EngineEnum {
  // Anthropic
  CLAUDE_3_5_SONNET_20240620 = 1, // default, sonnet training data cut off: 2024-06-20
  CLAUDE_3_5_SONNET_20241022 = 2, // sonnet training data cut off: 2024-10-22
  CLAUDE_3_OPUS_20240229 = 3,
  CLAUDE_3_5_HAIKU_20241022 = 4,
  // Chat
  GPT_4o_MINI_2024_07_18 = 5,
  GPT_4_TURBO_2024_04_09 = 6,
  // Gemini
  GEMINI_1_5_PRO = 7,
}
