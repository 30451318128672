import { ArrowDownTrayIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { uploadMedia } from "../utils";
import LoadingSpinner from "./Spinner/LoadingSpinner";

interface UploadMediaProps {
  mediaURL?: string;
  mediaURLError?: string;
  setMediaURL: (value: string) => void;
  setErrors: any;
}

const UploadMedia: React.FC<UploadMediaProps> = ({
  mediaURL,
  mediaURLError,
  setMediaURL,
  setErrors,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedSize, setUploadedSize] = useState<{
    loaded: number;
    total: number;
  }>({ loaded: 0, total: 0 });
  const [fileDetails, setFileDetails] = useState<{
    originalName: string;
    name: string;
    size: string;
    type: string;
  } | null>(null);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const token = useSelector((state: any) => state.auth.token);

  const sanitizeAndTimestampFileName = (originalName: string) => {
    const extensionIndex = originalName.lastIndexOf(".");
    const extension = originalName.substring(extensionIndex);
    const baseName = originalName.substring(0, extensionIndex);
    const timestamp = Date.now();
    const sanitizedBaseName = baseName.replace(/[.\s]+/g, "_").toLowerCase();
    return `${sanitizedBaseName}_${timestamp}${extension}`;
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const handleFileChange = async (file: File | null) => {
    if (!file) return;

    setErrors({});
    const fileName = sanitizeAndTimestampFileName(file.name);
    setFileDetails({
      originalName: file.name,
      name: fileName,
      size: formatFileSize(file.size),
      type: file.type,
    });
    setUploadedSize({ loaded: 0, total: file.size });

    setIsUploading(true);
    setUploadProgress(0);

    const controller = new AbortController();
    setAbortController(controller);

    try {
      const uploadedFileURL = await uploadMedia(file, token, {
        onProgress: (progress, loaded, total) => {
          setUploadProgress(progress);
          setUploadedSize({ loaded, total });
        },
        fileName,
        signal: controller.signal,
      });

      setMediaURL(uploadedFileURL);
    } catch (error) {
      if (error instanceof Error && error.name === "AbortError") {
        console.log("Upload cancelled by user");
      } else {
        console.error("Upload error:", error);
        toast.error("Failed to upload file");
      }
      setFileDetails(null);
      setUploadedSize({ loaded: 0, total: 0 });
    } finally {
      setIsUploading(false);
      setAbortController(null);
    }
  };

  const handleRemove = () => {
    if (isUploading && abortController) {
      abortController.abort();
    }
    setFileDetails(null);
    setMediaURL("");
    setUploadProgress(0);
    setIsUploading(false);
    setAbortController(null);
  };

  const getFileNameFromURL = (url: string) => {
    const urlParts = url.split("/");
    return urlParts[urlParts.length - 1];
  };

  const handleDownload = async (url: string, filename: string) => {
    if (isDownloading) return;

    setIsDownloading(true);
    setDownloadProgress(0);

    try {
      const response = await fetch(url);
      const reader = response.body?.getReader();
      const contentLength = +response.headers.get("Content-Length")!;
      let receivedLength = 0;
      const chunks = [];

      while (true) {
        const { done, value } = await reader!.read();
        if (done) break;
        chunks.push(value);
        receivedLength += value.length;
        setDownloadProgress(Math.round((receivedLength / contentLength) * 100));
      }

      const blob = new Blob(chunks);
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Download failed:", error);
      console.error("Download failed:", error);
      toast.error("Failed to download file");
    } finally {
      setIsDownloading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) handleFileChange(file);
    },
    accept: {
      "audio/mp3": [".mp3"],
      "video/mp4": [".mp4"],
    },
    maxSize: 150 * 1024 * 1024, // 150MB
    multiple: false,
    disabled: isUploading,
  });

  const removeTimestampFromFileName = (fileName: string) => {
    // Remove the timestamp portion (everything between last underscore and extension)
    return fileName.replace(/_\d+(\..+)$/, "$1");
  };

  return (
    <div className="space-y-1">
      <label
        htmlFor="media-upload"
        className="mb-1 flex justify-between uppercase text-xs tracking-wide font-medium leading-6 text-gray-900"
      >
        <div className="flex">Upload Audio or Video</div>
      </label>
      {mediaURL || fileDetails ? (
        <div className="rounded-lg border border-gray-200 p-4">
          <div className="flex items-center gap-4">
            <div className="h-12 w-12 flex-shrink-0 rounded-lg bg-gray-100 flex items-center justify-center">
              {fileDetails?.type?.includes("video") ||
              mediaURL?.includes("video") ? (
                <VideoIcon className="h-6 w-6 text-gray-400" />
              ) : (
                <AudioIcon className="h-6 w-6 text-gray-400" />
              )}
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-gray-900 truncate">
                  {fileDetails?.originalName
                    ? fileDetails.originalName
                    : removeTimestampFromFileName(
                        getFileNameFromURL(mediaURL || "")
                      )}
                </span>
                <div className="flex items-center gap-3">
                  {fileDetails?.size && (
                    <span className="text-sm text-gray-500">
                      {fileDetails.size}
                    </span>
                  )}
                  {!isUploading && mediaURL && (
                    <div className="flex items-center gap-1">
                      <button
                        type="button"
                        onClick={() =>
                          handleDownload(
                            mediaURL,
                            fileDetails?.originalName ||
                              getFileNameFromURL(mediaURL)
                          )
                        }
                        disabled={isDownloading}
                        className={`p-1 rounded-full transition-colors ${
                          isDownloading
                            ? "cursor-not-allowed"
                            : "hover:bg-gray-100"
                        }`}
                        title={
                          isDownloading ? "Downloading..." : "Download file"
                        }
                      >
                        {isDownloading ? (
                          <div className="h-4 w-4 flex items-center justify-center">
                            <LoadingSpinner size="16px" />
                          </div>
                        ) : (
                          <ArrowDownTrayIcon className="h-4 w-4 text-gray-500" />
                        )}
                      </button>
                      {isDownloading && downloadProgress > 0 && (
                        <span className="text-sm text-gray-500">
                          {downloadProgress}%
                        </span>
                      )}
                    </div>
                  )}
                  <button
                    type="button"
                    onClick={handleRemove}
                    disabled={isDownloading}
                    className={`p-1 rounded-full transition-colors ${
                      isDownloading ? "cursor-not-allowed" : "hover:bg-gray-100"
                    }`}
                    title={isUploading ? "Cancel upload" : "Remove file"}
                  >
                    <XMarkIcon className="h-4 w-4 text-gray-500" />
                  </button>
                </div>
              </div>
              {isUploading && (
                <>
                  <div className="h-1.5 w-full bg-gray-100 rounded-full mt-2">
                    <div
                      className="h-full bg-blue-500 rounded-full transition-all duration-300"
                      style={{ width: `${uploadProgress}%` }}
                    />
                  </div>
                  <div className="flex justify-between mt-1">
                    <span className="text-sm text-gray-500">
                      {formatFileSize(uploadedSize.loaded)} of{" "}
                      {formatFileSize(uploadedSize.total)}
                    </span>
                    <span className="text-sm text-gray-500">
                      {uploadProgress}%
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`
            relative rounded-lg border-2 border-dashed p-8
            ${isUploading ? "bg-gray-50" : "cursor-pointer hover:bg-gray-50/50 hover:border-primary/30"}
            ${mediaURLError ? "border-red-300" : "border-gray-200"}
            transition-all duration-200 ease-in-out
          `}
        >
          <input
            {...getInputProps()}
            id="media-upload"
          />
          <div className="flex flex-col items-center justify-center space-y-3 text-center">
            <div className="space-y-2">
              <p className="text-sm text-gray-600">
                <span className="text-primary font-medium">
                  Click to upload
                </span>{" "}
                or drag and drop
              </p>
              <p className="text-xs text-gray-400">MP3 or MP4 (max. 150MB)</p>
            </div>
          </div>
        </div>
      )}

      {mediaURLError && (
        <p className="text-sm text-red-600 mt-1">{mediaURLError}</p>
      )}
    </div>
  );
};

const VideoIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
    />
  </svg>
);

const AudioIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"
    />
  </svg>
);

export default UploadMedia;
