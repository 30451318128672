import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { ROUTE_TEMPLATES_EDIT } from "../../../constants/routes";
import { Article } from "../../../models";
import {
  formatDateUS,
  formatNumberWithCommas,
  getEngineName,
  getEngineSVG,
  getSourceSVG,
} from "../../../utils";
import ArticleActions from "./ArticleActions";

import cn from "classnames";
import useUser from "../../../hooks/useUser";
import ArticleCopy from "./ArticleCopy";
import ArticleStatus from "./ArticleStatus";

const ArticleTable = ({
  articles,
  handleArticleAction,
}: {
  articles: Article[];
  handleArticleAction: (
    id: string,
    name: string,
    url: string,
    action: "" | "view" | "download"
  ) => void;
}) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const showEngineDetails = user?.is_super || false;
  return (
    <>
      <table className="hidden md:block min-w-full border-separate border-spacing-0 border-r border-l border-t overflow-x-auto">
        <thead>
          <tr>
            <th
              scope="col"
              className="w-full p-3.5 text-start text-xs uppercase tracking-wide text-gray-500 bg-gray-50 border-b rounded-t-lg truncate overflow-hidden"
            >
              Title
            </th>
            <th
              scope="col"
              className="w-full p-3.5 text-start text-xs uppercase tracking-wide text-gray-500 bg-gray-50 border-b rounded-t-lg truncate overflow-hidden"
            >
              Source
            </th>
            <th
              scope="col"
              className="w-full p-3.5 text-start text-xs uppercase tracking-wide text-gray-500 bg-gray-50 border-b rounded-t-lg truncate overflow-hidden"
            >
              Template
            </th>
            <th
              scope="col"
              className="w-full p-3.5 text-start text-xs uppercase tracking-wide text-gray-500 bg-gray-50 border-b rounded-t-lg truncate overflow-hidden"
            >
              Words
            </th>
            <th
              scope="col"
              className="w-full p-3.5 text-start text-xs uppercase tracking-wide text-gray-500 bg-gray-50 border-b rounded-t-lg truncate overflow-hidden"
            >
              Tokens Used
            </th>
            {showEngineDetails && (
              <th
                scope="col"
                className="w-full p-3.5 text-start text-xs uppercase tracking-wide text-gray-500 bg-gray-50 border-b rounded-t-lg truncate overflow-hidden"
              >
                Engine
              </th>
            )}
            <th
              scope="col"
              className="w-full p-3.5 text-start text-xs uppercase tracking-wide text-gray-500 bg-gray-50 border-b rounded-t-lg truncate overflow-hidden"
            >
              Status
            </th>
            <th
              scope="col"
              className="w-full p-3.5 text-start text-xs uppercase tracking-wide text-gray-500 bg-gray-50 border-b rounded-t-lg truncate overflow-hidden"
            >
              Created
            </th>
            <th
              scope="col"
              className="w-full p-3.5 text-start text-xs uppercase tracking-wide text-gray-500 bg-gray-50 border-b rounded-t-lg truncate overflow-hidden"
            ></th>
          </tr>
        </thead>
        <tbody>
          {articles.map((article: Article) => (
            <tr key={article.article_id}>
              <td className="w-full p-2 px-3.5 text-start text-sm text-gray-600 border-b truncate overflow-hidden max-w-sm">
                <div
                  className={cn("flex items-center gap-3", {
                    "text-red-500": article["generate_error"],
                  })}
                >
                  <img
                    alt="source"
                    src={getSourceSVG(article["source_id"])}
                    className="size-5"
                  />
                  <div className="truncate overflow-hidden">
                    {article.title}
                  </div>
                </div>
              </td>
              <td className="w-full p-2 px-3.5 text-start text-sm text-gray-600 border-b truncate overflow-hidden">
                <div className="flex justify-end">
                  <a
                    href={article["media_url"]}
                    target="_blank"
                    rel="noreferrer"
                    className="group"
                  >
                    <Button
                      id="open-link"
                      tooltip="Open link in a new tab"
                      variant="icon"
                      onClick={() => {}}
                    >
                      <ArrowTopRightOnSquareIcon className="size-4 " />
                    </Button>
                  </a>
                  <ArticleCopy
                    mediaUrl={article["media_url"]}
                    articleId={article["article_id"]}
                  />
                </div>
              </td>
              <td className="w-full p-2 px-3.5 text-start text-sm text-gray-600 border-b truncate overflow-hidden">
                <div
                  role="button"
                  tabIndex={0}
                  className={cn(
                    "truncate overflow-hidden text-primary ",
                    showEngineDetails ? "max-w-[12rem]" : "max-w-sm"
                  )}
                  onClick={() => {
                    navigate(
                      generatePath(ROUTE_TEMPLATES_EDIT, {
                        id: article["template_id"],
                      })
                    );
                  }}
                >
                  {article.template_name}
                </div>
              </td>
              <td className="w-full p-2 px-3.5 text-start text-sm text-gray-600 border-b truncate overflow-hidden">
                {formatNumberWithCommas(article.article_word_count) || "-"}
              </td>
              <td className="w-full p-2 px-3.5 text-start text-sm text-gray-600 border-b truncate overflow-hidden">
                {formatNumberWithCommas(article["tokens_used"]) || "-"}
              </td>
              {showEngineDetails && (
                <td className="w-full p-2 px-6 text-start text-sm text-gray-600 border-b truncate overflow-hidden">
                  {article.engine_id ? (
                    <div className="flex gap-1">
                      <img
                        alt="engine"
                        src={getEngineSVG(article["engine_id"])}
                        className="w-5 h-5"
                      />
                      {getEngineName(article["engine_id"])}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
              )}
              <td className="w-full p-2 px-3.5 text-start text-sm text-gray-600 border-b truncate overflow-hidden">
                <ArticleStatus
                  article_status_id={article["article_status_id"]}
                  generate_error={article["generate_error"]}
                />
              </td>
              <td className="w-full p-2 px-3.5 text-start text-sm text-gray-600 border-b truncate overflow-hidden">
                <div className="truncate overflow-hidden">
                  {formatDateUS(article["created_at"])}
                </div>
              </td>
              <td className="w-full p-2 px-3.5 text-start text-sm text-gray-600 border-b truncate overflow-hidden">
                {article.s3_article_url ? (
                  <ArticleActions
                    articleId={article["article_id"]}
                    articleUrl={article["s3_article_url"]}
                    onView={() =>
                      handleArticleAction(
                        article["article_id"],
                        article["title"],
                        article["s3_article_url"],
                        "view"
                      )
                    }
                  />
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Cards */}
      <div className="flex flex-col gap-6 md:hidden">
        {articles.map((article: Article, index: number) => {
          const {
            title,
            template_name,
            generate_error,
            created_at,
            article_word_count,
            article_status_id,
            tokens_used,
          } = article;
          return (
            <div
              key={index}
              className="border rounded shadow p-4 flex flex-col gap-4"
            >
              <div
                className={cn("flex items-center gap-3", {
                  "text-red-500": generate_error,
                })}
              >
                <img
                  alt="source"
                  src={getSourceSVG(article["source_id"])}
                  className="size-5"
                />
                <div className="truncate overflow-hidden">{title}</div>
              </div>
              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Template
                </div>
                <div className="truncate overflow-hidden text-sm">
                  {template_name}
                </div>
              </div>
              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Status
                </div>
                <ArticleStatus
                  article_status_id={article_status_id}
                  generate_error={generate_error}
                />
              </div>
              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Word Count
                </div>
                <div className="truncate overflow-hidden text-sm">
                  {formatNumberWithCommas(article_word_count) || "-"}
                </div>
              </div>
              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Tokens Used
                </div>
                <div className="truncate overflow-hidden text-sm">
                  {formatNumberWithCommas(tokens_used) || "-"}
                </div>
              </div>
              {showEngineDetails && (
                <div>
                  <div className="uppercase text-xs font-semibold text-gray-500">
                    Engine
                  </div>
                  <div className="truncate overflow-hidden text-sm mt-1">
                    {article.engine_id ? (
                      <div className="flex gap-1">
                        <img
                          alt="engine"
                          src={getEngineSVG(article["engine_id"])}
                          className="w-5 h-5"
                        />
                        {getEngineName(article["engine_id"])}
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              )}
              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Created At
                </div>
                <div className="truncate overflow-hidden text-sm">
                  {formatDateUS(created_at)}
                </div>
              </div>
              <div>
                <div className="h-px w-full bg-gray-200" />
                <div className="mt-4 flex gap-1 items-center">
                  <Button
                    variant="light-primary"
                    paddingZero
                    onClick={() => {
                      window.open(article["media_url"], "_blank");
                    }}
                    className="flex gap-1 items-center p-1"
                  >
                    <ArrowTopRightOnSquareIcon className="size-4" />
                    Source
                  </Button>
                  <div className="h-4 w-px bg-gray-200" />
                  <ArticleActions
                    articleId={article["article_id"]}
                    articleUrl={article["s3_article_url"]}
                    onView={() =>
                      handleArticleAction(
                        article["title"],
                        article["title"],
                        article["s3_article_url"],
                        "view"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default React.memo(ArticleTable);
