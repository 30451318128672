import React from "react";
import RadioGroup from "../../../components/RadioGroup";
import { SourceEnum } from "../../../enums/template";
import { getSourceSVG } from "../../../utils";

const PlatformSVG = ({ title, src }: { title: string; src: any }) => (
  <div className="flex gap-1 items-center">
    <img
      src={src}
      alt={title}
      className="w-4 h-4"
    />
    {title}
  </div>
);

const platformOptions = [
  {
    id: SourceEnum.INSTAGRAM_REELS,
    name: (
      <PlatformSVG
        title="Reels"
        src={getSourceSVG(SourceEnum.INSTAGRAM_REELS)}
      />
    ),
  },
  {
    id: SourceEnum.YOUTUBE_SHORTS,
    name: (
      <PlatformSVG
        title="Shorts"
        src={getSourceSVG(SourceEnum.YOUTUBE_SHORTS)}
      />
    ),
  },
  {
    id: SourceEnum.YOUTUBE_VIDEOS,
    name: (
      <PlatformSVG
        title="Videos"
        src={getSourceSVG(SourceEnum.YOUTUBE_VIDEOS)}
      />
    ),
  },
  {
    id: SourceEnum.APPLE_PODCASTS,
    name: (
      <PlatformSVG
        title="Podcast"
        src={getSourceSVG(SourceEnum.APPLE_PODCASTS)}
      />
    ),
  },
  {
    id: SourceEnum.UPLOAD_MEDIA,
    name: (
      <PlatformSVG
        title="Upload Media"
        src={getSourceSVG(SourceEnum.UPLOAD_MEDIA)}
      />
    ),
  },
];

const PlatformSelector = React.memo(
  ({
    source_id,
    handlePlatformChange,
  }: {
    source_id: SourceEnum;
    handlePlatformChange: (sourceId: SourceEnum) => void;
  }) => {
    return (
      <div className="">
        <label
          htmlFor="platform_selector"
          className="mb-1 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase"
        >
          Platform
        </label>

        <RadioGroup
          options={platformOptions}
          name="source_id"
          selected={platformOptions.find(
            ({ id }) => id === (source_id || SourceEnum.INSTAGRAM_REELS)
          )}
          onClick={(option) => handlePlatformChange(option.id)}
        />
      </div>
    );
  }
);

export default PlatformSelector;
