import React from "react";
import { SourceEnum } from "../../../enums/template";
import useUser from "../../../hooks/useUser";
import { Template } from "../../../models";
import AdditionalSettings from "./AdditionalSettings";
import BrandSettings from "./BrandSettings";
import EmbedMedia from "./EmbedMedia";
import Engine from "./Engine";
import Faq from "./FAQ";
import ImageSettings from "./ImageSettings";

type AdvancedSettingsProps = {
  values: Template;
  setFieldValue: any;
};

const AdvancedSettings = (payload: AdvancedSettingsProps) => {
  const { user } = useUser();
  const isUpload = payload.values.source_id === SourceEnum.UPLOAD_MEDIA;
  return (
    <div className="animate-fadeIn space-y-6">
      <ImageSettings {...payload} />
      {!isUpload && <EmbedMedia {...payload} />}
      <Faq {...payload} />
      <BrandSettings {...payload} />
      <AdditionalSettings {...payload} />
      {user?.is_super && <Engine {...payload} />}
    </div>
  );
};

export default AdvancedSettings;
