import { FormikErrors } from "formik";
import React from "react";
import SelectV2 from "../../../components/CustomSelect";
import { EngineEnum } from "../../../enums/engine";
import { Template } from "../../../models";
import { getEngineSVG } from "../../../utils";
import TemplateContainer from "./common/TemplateContainer";

export const engineOptions = [
  {
    value: EngineEnum.CLAUDE_3_5_SONNET_20240620,
    label: "claude-3-5-sonnet-20240620",
    icon: getEngineSVG(EngineEnum.CLAUDE_3_5_SONNET_20240620),
    description: "",
  },
  {
    value: EngineEnum.CLAUDE_3_5_SONNET_20241022,
    label: "claude-3-5-sonnet-20241022",
    icon: getEngineSVG(EngineEnum.CLAUDE_3_5_SONNET_20241022),
    description: "",
  },
  {
    value: EngineEnum.CLAUDE_3_OPUS_20240229,
    label: "claude-3-opus-20240229",
    icon: getEngineSVG(EngineEnum.CLAUDE_3_OPUS_20240229),
    description: "",
  },
  {
    value: EngineEnum.CLAUDE_3_5_HAIKU_20241022,
    label: "claude-3-5-haiku-20241022",
    icon: getEngineSVG(EngineEnum.CLAUDE_3_5_HAIKU_20241022),
    description: "",
  },
  {
    value: EngineEnum.GPT_4o_MINI_2024_07_18,
    label: "gpt-4o-mini-2024-07-18",
    icon: getEngineSVG(EngineEnum.GPT_4o_MINI_2024_07_18),
    description: "",
  },
  {
    value: EngineEnum.GPT_4_TURBO_2024_04_09,
    label: "gpt-4-turbo-2024-04-09",
    icon: getEngineSVG(EngineEnum.GPT_4_TURBO_2024_04_09),
    description: "",
  },
  {
    value: EngineEnum.GEMINI_1_5_PRO,
    label: "gemini-1.5-pro",
    icon: getEngineSVG(EngineEnum.GEMINI_1_5_PRO),
    description: "",
  },
];

type EngineProps = {
  values: Partial<Template>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<any>>;
};

const Engine = ({ values, setFieldValue }: EngineProps) => {
  return (
    <div className="animate-fadeIn">
      <TemplateContainer isSuperUserFeature>
        <SelectV2
          label="Engine"
          placeholder="-"
          options={engineOptions}
          selected={engineOptions.find(
            (option) => option.value === values?.engine_id
          )}
          setSelected={(option) => {
            setFieldValue("engine_id", option?.value || null);
          }}
          isSuperUserFeature
          maxMenuHeight={125}
        />
      </TemplateContainer>
    </div>
  );
};

export default Engine;
